<template>
  <jmodal v-if="showModal" @close="showModal = false">
    <template v-slot:title> 人事主管 </template>

    <template v-slot:content>
        <div class="w-100 text-center h5 my-3">选择模式</div>
        <div class="text-center">
            <div class="form-check form-check-inline m-2">
                <input class="form-check-input" type="radio" id="inlineRadio1" value="0">
                <label class="form-check-label" for="inlineRadio1">日结</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio2" value="1">
                <label class="form-check-label" for="inlineRadio2">小时工</label>
            </div>
        </div>
        <button class="btn btn-success w-100 my-3">确 定</button>
    </template>
    
  </jmodal>
</template>

<script>

export default {
  name: "signup",
  setup() {
      let showModal = true

      return {
          showModal
      }
  }
};
</script>

<style>
</style>